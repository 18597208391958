import React from 'react'
import { Link } from 'react-router-dom'
import moment from '../moment'
import { withDataTransform } from '../hoc/database'

const CardInfo = ({ patientKey, ...props }) => (
  <div className="card-info">
    <h4>Напоминания</h4>

    <ul className="card-reminders">
      <li>
        <CardInfoReminders
          url={`${props.match.url}/blood`}
          title="Биохимический анализ крови"
          reminders={props.reminders_blood || {}}
        />
      </li>
      <li>
        <CardInfoReminders
          url={`${props.match.url}/feces`}
          title="Анализы кала"
          reminders={props.reminders_feces || {}}
        />
      </li>
    </ul>

    <h4>Прочие действия</h4>

    <ul className="actions">
      <li>
        <Link to={`/card/${patientKey}/edit`}>
          Изменить информацию о пациенте
        </Link>
      </li>
      <li>
        <button type="button" onClick={props.removeCard}>
          Удалить карточку
        </button>
      </li>
    </ul>
  </div>
)

const CardInfoReminders = ({ url, title, reminders: { active, total } }) => (
  <div>
    <span className="title">
      <Link to={url}>{title}</Link>
    </span>
    <span>
      <span className={active > 0 ? 'alert' : ''}>{active}</span>
      {' / '}
      <span>{total}</span>
    </span>
  </div>
)

const transform = snapshot => {
  const now = moment().format('YYYY-MM-DD')
  let total = 0
  let active = 0

  snapshot.forEach(child => {
    const last = child.val()
    if (last.remind) {
      total++
      if (last.remind <= now) {
        active++
      }
    }
  })

  return { total, active }
}

const enhance = withDataTransform({
  reminders_blood: {
    path: props => `tests/${props.patientKey}/blood/last`,
    transform,
  },
  reminders_feces: {
    path: props => `tests/${props.patientKey}/feces/last`,
    transform,
  },
})

export default enhance(CardInfo)
