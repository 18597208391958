import React from 'react'
import moment from '../moment'

const formatAge = born => {
  const localeData = moment.localeData()
  const ageYears = moment.duration(moment().diff(moment(born))).get('years')
  return localeData.relativeTime(ageYears, false, 'yy')
}

const Profile = props => {
  const { born, phone, email, job, infertility } = props.patient

  return (
    <div className="profile flex-row">
      <div className="profile-prop">
        <span className="label">Возраст</span>
        <span className="value" title={born}>
          {born ? formatAge(born) : '(нет)'}
        </span>
      </div>
      <div className="profile-prop">
        <span className="label">Телефон</span>
        <span className="value">{phone || '(нет)'}</span>
      </div>
      <div className="profile-prop">
        <span className="label">E-mail</span>
        <span className="value">{email || '(нет)'}</span>
      </div>
      <div className="profile-prop">
        <span className="label">Профессия</span>
        <span className="value">{job || '(нет)'}</span>
      </div>

      {infertility && (
        <div className="profile-prop">
          <span className="label">Бесплодие</span>
          <span className="value">Да</span>
        </div>
      )}
    </div>
  )
}

export default Profile
