import { withProps } from 'recompose'

// specific pages are store in 'pages' directory
import PageAntropometry from './pages/Antropometry'
import PageParams from './pages/Params'
import PageRation from './pages/Ration'
import PageTests from './pages/Tests'
import PageToolTests from './pages/ToolTests'
import PageAndrology from './pages/Andrology'
import PageDiagnosis from './pages/Diagnosis'
import PageProcedures from './pages/Procedures'
import PageConsultations from './pages/Consultations'

const pages = {
  antropometry: {
    title: 'Антропометрические показатели',
    component: PageAntropometry,
  },
  diseases: {
    title: 'Сопутствующие заболевания',
    component: withProps({ set: 'diseases' })(PageParams),
  },
  insulinres: {
    title: 'Оценка инсулиновой резистивности',
    component: withProps({ set: 'insulin_resistance' })(PageParams),
  },
  gynae: {
    title: 'Гинекологический анамнез',
    component: withProps({ set: 'gynae' })(PageParams),
  },
  andrology: {
    title: 'Андрологический анамнез',
    component: PageAndrology,
  },
  ration: {
    title: 'Пищевой рацион',
    component: PageRation,
  },
  tooltests: {
    title: 'Инструментальные исследования',
    component: PageToolTests, // withProps({ writing: 'tooltests' })(PageBigText)
  },
  blood_bio: {
    title: 'Биохимический анализ крови',
    component: withProps({
      testsType: 'blood',
      testsKey: 'blood_tests',
      groupsKey: 'blood_tests_groups',
    })(PageTests),
  },
  parasitosis: {
    title: 'Диагностика паразитозов',
    component: withProps({
      testsType: 'parasitosis',
      testsKey: 'parasitosis',
    })(PageTests),
  },
  osipov: {
    title: 'Дисбиоз по Осипову',
    component: withProps({
      testsType: 'osipov',
      testsKey: 'dysbiosis_osipov',
    })(PageTests),
  },
  cortisol_dgea: {
    title: 'Кортизол и ДГЕА в слюне',
    component: withProps({
      testsType: 'cortisol_dgea',
      testsKey: 'cortisol',
    })(PageTests),
  },
  saliva: {
    title: 'Анализы слюны',
    component: withProps({
      testsType: 'saliva',
      testsKey: 'saliva',
    })(PageTests),
  },
  amino_acids: {
    title: 'Аминокислоты в моче',
    component: withProps({
      testsType: 'amino_acids',
      testsKey: 'amino_acids',
    })(PageTests),
  },
  organic_acids: {
    title: 'Органические кислоты',
    component: withProps({
      testsType: 'organic_acids',
      testsKey: 'organic_acids',
      groupsKey: 'organic_acids_groups',
    })(PageTests),
  },
  methylated_arginine: {
    title: 'Метилированные производные аргинина',
    component: withProps({
      testsType: 'xma',
      testsKey: 'xma',
    })(PageTests),
  },
  blood_cli: {
    title: 'Клинический анализ крови',
    component: withProps({
      testsType: 'blood_cli',
      testsKey: 'blood_clinic',
    })(PageTests),
  },
  spermogram: {
    title: 'Спермограмма',
    component: withProps({
      testsType: 'spermogram',
      testsKey: 'spermogram',
    })(PageTests),
  },
  feces: {
    title: 'Анализы кала',
    component: withProps({
      testsType: 'feces',
      testsKey: 'feces_tests',
    })(PageTests),
  },
  diagnosis: {
    title: 'Диагноз',
    component: PageDiagnosis,
  },
  procedures: {
    title: 'Процедурный лист',
    component: PageProcedures,
  },
  consultations: {
    title: 'Консультации специалистов',
    component: PageConsultations,
  },
}

export default pages
