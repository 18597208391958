import React from 'react'

const PatientEditor = ({ onSave, onCancel, ...props }) => {
  const handleSubmit = event => {
    event.preventDefault()

    const form = event.currentTarget

    const data = ['name', 'born', 'phone', 'email', 'job'].reduce(
      (data, name) => {
        data[name] = form[name].value
        return data
      },
      {},
    )

    data.infertility = form.infertility.checked

    console.log(data)
    onSave(data)
    form.reset()
  }

  return (
    <div className="patient-editor">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          defaultValue={props.name}
          placeholder="Фамилия, имя, отчество"
          required
        />
        <input
          type="date"
          name="born"
          defaultValue={props.born}
          placeholder="Дата рождения"
        />
        <input
          type="text"
          name="phone"
          defaultValue={props.phone}
          placeholder="Телефон"
        />
        <input
          type="email"
          name="email"
          defaultValue={props.email}
          placeholder="Электропочта"
        />
        <input
          type="text"
          name="job"
          defaultValue={props.job}
          placeholder="Профессия"
        />
        <label>
          <input
            type="checkbox"
            name="infertility"
            defaultChecked={props.infertility}
          />{' '}
          Бесплодие
        </label>
        <div>
          <button type="submit">Сохранить</button>
          <button type="button" onClick={onCancel}>
            Отменить
          </button>
        </div>
      </form>
    </div>
  )
}

export default PatientEditor
